import React from "react";
import PropTypes from 'prop-types';

AppCheckbox.propTypes = {
  check: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string
};

export function AppCheckbox(props) {
  const appCircleStyle = props.color && {
    background: props.color
  };

  const appIconStyle = props.icon && {
    display: "block",
    maskImage: `url(${props.icon})`,
    WebkitMaskImage: `url(${props.icon})`
  };

  if (props.isLoading) {
    return (
      <div className="support-form-app-checkbox is-loading">
        <div className="support-form-app-checkbox__tile is-loading">
          <div className="support-form-app-checkbox__app-circle is-loading">
            <div className="support-form-app-checkbox__app-icon is-loading"/>
          </div>
          <div className="support-form-app-checkbox__title is-loading"/>
        </div>
      </div>
    )
  }

  return (
    <label className="support-form-app-checkbox">
      <input
        className="support-form-app-checkbox__input"
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.check === props.value}
        onChange={props.onChange}
      />
      <div className="support-form-app-checkbox__tile">
        <div className="support-form-app-checkbox__app-circle" style={appCircleStyle}>
          <div
            className={`support-form-app-checkbox__app-icon support-form-app-checkbox__app-icon--${props.iconClass}`}
            style={appIconStyle}/>
          <div className="support-form-app-checkbox__check-circle">
            <div className="support-form-app-checkbox__check-icon"/>
          </div>
        </div>
        <div className="support-form-app-checkbox__title">{props.title}</div>
      </div>
    </label>
  );
}
