import React from "react";
import PropTypes from 'prop-types';

import {flowManager} from "../managers/FlowManager";

StepHeader.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  index: PropTypes.number,
  isLoading: PropTypes.bool,
  question: PropTypes.string
}

export function StepHeader(props) {
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  let stepPosition = "";

  if (steps.length > props.index + 1) {
    stepPosition = "is-previous";
  }
  if (steps.length === props.index + 1) {
    stepPosition = "is-current";
  }
  if (steps.length < props.index + 1) {
    stepPosition = "is-next";
  }

  return (
    <div className="support-form-step-header">
      <div className={`support-form-step-header__icon-circle ${stepPosition}`}>
        <div className={`support-form-step-header__icon ${stepPosition} ${props.isLoading && "is-loading"}`}/>
      </div>
      <div className={`support-form-step-header__side-line ${stepPosition}`}/>
      <h2 className={`support-form-step-header__question ${stepPosition}`}>{props.question}</h2>
      <p className={`support-form-step-header__description ${stepPosition}`}>{props.description}</p>
      {props.children}
    </div>
  );
}
