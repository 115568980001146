import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {flowManager} from "../managers/FlowManager";
import {userManager} from "../managers/UserManager";
import {StepHeader} from "../components/StepHeader";
import {StepCategory} from "../components/StepCategory";
import {TileCheckbox} from "../components/TileCheckbox";
import {SubCategoryTile} from "../components/SubCategoryTile";
import {dataKeys} from "../enums/dataKeys";

CloudCategoryStep.propTypes = {
  index: PropTypes.number
}

export function CloudCategoryStep(props) {
  const name = CloudCategoryStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const stepRef = useRef(null);
  const [checkValue, setCheckValue] = useState("");
  const homey = userManager.getSelectedHomey();
  const options = {};

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name])

  function handleSelect(e) {
    const value = e.target.value;
    const data = options[value].data;
    const action = data.action;
    const entry = { name, value, ...data };

    flowManager.nextActionStep(props.index, entry, action);
  }

  // Apps & Devices
  options[`appsAndDevices-0`] = {
    title: "Apps",
    icon: "apps",
    data: { ...CloudCategoryStep.apps }
  };
  options[`appsAndDevices-1`] = {
    title: "Devices",
    icon: "devices",
    data: { ...CloudCategoryStep.devices }
  };

  // Automation
  options[`automation-0`] = {
    title: "Flow",
    icon: "flow",
    data: { ...CloudCategoryStep.flow }
  };
  options[`automation-1`] = {
    title: "Logic",
    icon: "logic",
    data: { ...CloudCategoryStep.logic }
  };

  // Monitoring
  options[`monitoring-0`] = {
    title: "Insights",
    icon: "insights",
    data: { ...CloudCategoryStep.insights }
  };
  options[`monitoring-1`] = {
    title: "Energy",
    icon: "energy",
    data: { ...CloudCategoryStep.energy }
  };

  // Platform
  options['platform-0'] = {
    title: "Mobile App",
    icon: "phone",
    data: { ...CloudCategoryStep.mobileApp }
  };
  options['platform-1'] = {
    title: "Web App",
    icon: "webapp",
    data: { ...CloudCategoryStep.webApp }
  };

  // Voice Assistants
  options[`voiceAssistants-0`] = {
    title: "Amazon Alexa",
    icon: "alexa",
    data: { ...CloudCategoryStep.alexa }
  };
  options[`voiceAssistants-1`] = {
    title: "Google Assistant",
    icon: "google",
    data: { ...CloudCategoryStep.google }
  };
  options[`voiceAssistants-2`] = {
    title: "Siri",
    icon: "siri",
    data: { ...CloudCategoryStep.siri }
  };

  // Warranty
  options[`warranty-0`] = {
    title: "Defects",
    icon: "defects",
    data: { ...CloudCategoryStep.defects }
  };
  options[`warranty-1`] = {
    title: "Returns",
    icon: "returns",
    data: { ...CloudCategoryStep.returns }
  };

  // Wireless Technologies
  options[`wireless-0`] = {
    title: "433 MHz",
    icon: "433mhz",
    data: { ...CloudCategoryStep["433mhz"] }
  };
  options[`wireless-1`] = {
    title: "868 MHz",
    icon: "868mhz",
    data: { ...CloudCategoryStep["868mhz"] }
  };
  options[`wireless-2`] = {
    title: "Bluetooth",
    icon: "bluetooth",
    data: { ...CloudCategoryStep.bluetooth }
  };
  options[`wireless-3`] = {
    title: "Infrared",
    icon: "infrared",
    data: { ...CloudCategoryStep.infrared }
  };
  options[`wireless-4`] = {
    title: "Wi-Fi",
    icon: "wifi",
    data: homey.bridges.length > 0 ? { ...CloudCategoryStep.withBridge.wifi } : { ...CloudCategoryStep.wifi }
  };
  options[`wireless-5`] = {
    title: "Zigbee",
    icon: "zigbee",
    data: homey.bridges.length > 0 ? { ...CloudCategoryStep.withBridge.zigbee } : { ...CloudCategoryStep.zigbee }
  };
  options[`wireless-6`] = {
    title: "Z-Wave",
    icon: "z-wave",
    data: homey.bridges.length > 0 ? { ...CloudCategoryStep.withBridge.zwave } : { ...CloudCategoryStep.zwave }
  };

  // More
  options[`more-0`] = {
    title: "Family",
    icon: "family",
    data: { ...CloudCategoryStep.family }
  };
  if (homey.bridges.length > 0) {
    options[`more-1`] = {
      title: "Homey Bridge",
      icon: "homey-bridge",
      data: { ...CloudCategoryStep.homeyBridge }
    };
  }
  options[`more-2`] = {
    title: "Other",
    icon: "other",
    data: { ...CloudCategoryStep.other }
  };

  return (
    <>
      <div ref={stepRef} tabIndex={0} className="support-form-step">
        <StepHeader
          index={props.index}
          question="How can we help you with Homey?"
          description="Choose the category that describes your question or problem best."
        />
        <div className="support-form-step__categories">
          <StepCategory heading="Apps & Devices">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("appsAndDevices") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Automation">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("automation") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Monitoring">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("monitoring") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Platform">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("platform") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Voice Assistants">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("voiceAssistants") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Warranty">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("warranty") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Wireless Technologies">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("wireless") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="More">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("more") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
        </div>
      </div>
      {steps.length === props.index + 1 && (
        <div className="support-form-step">
          <StepHeader
            index={props.index + 1}
            question="Select one of the above options."
          />
        </div>
      )}
    </>
  );
}

CloudCategoryStep.apps = {
  action: "apps",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["apps"]
}
CloudCategoryStep.devices = {
  action: "devices",
  [dataKeys.articles]: [4409624214034, 360025308654, 360012079234, 360026209853, 360012079134],
  [dataKeys.tags]: ["devices"]
}
CloudCategoryStep.flow = {
  action: "flow",
  [dataKeys.articles]: [360009669174, 360012168019, 360010575399, 360015464674, 4410240765586, 360025313254, 360012142153],
  [dataKeys.tags]: ["flow"]
}
CloudCategoryStep.logic = {
  action: "logic",
  [dataKeys.articles]: [4410240765586],
  [dataKeys.tags]: ["logic"]
}
CloudCategoryStep.insights = {
  action: "insights",
  [dataKeys.articles]: [360015370454, 4409687231890],
  [dataKeys.tags]: ["insights"]
}
CloudCategoryStep.energy = {
  action: "energy",
  [dataKeys.articles]: [360010187820, 360010282619],
  [dataKeys.tags]: ["energy"]
}
CloudCategoryStep.mobileApp = {
  action: "mobileApp",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["mobile-app"]
}
CloudCategoryStep.webApp = {
  action: "webApp",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["web-app"]
}
CloudCategoryStep.alexa = {
  action: "alexa",
  [dataKeys.articles]: [360020154553, 4409841950738, 4409837342354],
  [dataKeys.tags]: ["alexa"]
}
CloudCategoryStep.google = {
  action: "google",
  [dataKeys.articles]: [360022832954, 4409850306450, 4409858015250],
  [dataKeys.tags]: ["google-assistant"]
}
CloudCategoryStep.siri = {
  action: "siri",
  [dataKeys.articles]: [4409843046290],
  [dataKeys.tags]: ["siri"]
}
CloudCategoryStep.defects = {
  action: "defects",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["defects"]
}
CloudCategoryStep.returns = {
  action: "returns",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["returns"]
}
CloudCategoryStep["433mhz"] = {
  action: "433mhz",
  [dataKeys.articles]: [4409763123602, 360020540614],
  [dataKeys.tags]: ["433mhz"]
}
CloudCategoryStep["868mhz"] = {
  action: "868mhz",
  [dataKeys.articles]: [4409763154322],
  [dataKeys.tags]: ["868mhz"]
}
CloudCategoryStep.bluetooth = {
  action: "bluetooth",
  [dataKeys.articles]: [4409762463122],
  [dataKeys.tags]: ["bluetooth"]
}
CloudCategoryStep.infrared = {
  action: "infrared",
  [dataKeys.articles]: [4409790615698],
  [dataKeys.tags]: ["infrared"]
}
CloudCategoryStep.wifi = {
  action: "wifi",
  [dataKeys.articles]: [4409776821010],
  [dataKeys.tags]: ["wifi"]
}
CloudCategoryStep.zigbee = {
  action: "zigbee",
  [dataKeys.articles]: [4409769766418, 360019240259, 360015669840],
  [dataKeys.tags]: ["zigbee"]
}
CloudCategoryStep.zwave = {
  action: "zwave",
  [dataKeys.articles]: [4409762628114, 360012182213, 4410500384274],
  [dataKeys.tags]: ["z-wave"]
}
CloudCategoryStep.family = {
  action: "family",
  [dataKeys.articles]: [360012615814, 360012141853],
  [dataKeys.tags]: ["family"]
}
CloudCategoryStep.homeyBridge = {
  action: "homeyBridge",
  [dataKeys.articles]: [360018783339, 4409791906578, 360018715980, 360012710333, 4405896101778, 4405896417426],
  [dataKeys.tags]: ["bridge"]
}
CloudCategoryStep.other = {
  action: "other",
  [dataKeys.articles]: [4409791942802, 9187264180508, 7494444235292],
  [dataKeys.tags]: ["other"]
}

CloudCategoryStep.withBridge = {
  wifi: {
    action: "wifi",
    [dataKeys.articles]: [4409776821010, 4405895776402],
    [dataKeys.tags]: ["wifi"]
  },
  zigbee: {
    action: "zigbee",
    [dataKeys.articles]: [4409769766418, 360019240259, 360015669840, 4414431713682],
    [dataKeys.tags]: ["zigbee"]
  },
  zwave: {
    action: "zwave",
    [dataKeys.articles]: [4409762628114, 360012182213, 4410500384274, 4405895977362],
    [dataKeys.tags]: ["z-wave"]
  }
}
