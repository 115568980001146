import {MainCategoryStep} from "../steps/MainCategoryStep/MainCategoryStep";
import {AccountCategoryStep} from "../steps/AccountCategoryStep";
import {CloudCategoryStep} from "../steps/CloudCategoryStep";
import {ProCategoryStep} from "../steps/ProCategoryStep";
import {KnowledgeBaseStep} from "../steps/KnowledgeBaseStep";
import {AppsStep} from "../steps/AppsStep/AppsStep";
import {ContactDeveloperStep} from "../steps/ContactDeveloperStep";
import {EmailDeveloperStep} from "../steps/EmailDeveloperStep";
import {TicketStep} from "../steps/TicketStep/TicketStep";

export const stepTypes = {
  step: 'step',
  actionStep: 'actionStep',
  finalStep: 'finalStep',
}

export const flowConfig =
  actionStep(MainCategoryStep, {
      [MainCategoryStep.account.action]:
        actionStep(AccountCategoryStep, {
            [AccountCategoryStep.nameAndMail.action]:
              finalStep(TicketStep),
            [AccountCategoryStep.profile.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [AccountCategoryStep.delete.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [AccountCategoryStep.other.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [AccountCategoryStep.password.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [AccountCategoryStep.mfa.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
          }
        ),
      [MainCategoryStep.homey.cloud.action]:
        actionStep(CloudCategoryStep, {
            [CloudCategoryStep.apps.action]:
              actionStep(AppsStep, {
                  [AppsStep.contactSupport.action]:
                    step(KnowledgeBaseStep,
                      finalStep(TicketStep)
                    ),
                  [AppsStep.contactDeveloper.action]:
                    step(ContactDeveloperStep,
                      finalStep(EmailDeveloperStep)
                    ),
                  [AppsStep.notListed.action]:
                    step(KnowledgeBaseStep,
                      finalStep(TicketStep)
                    ),
                }
              ),
            [CloudCategoryStep.devices.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.flow.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.logic.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.insights.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.energy.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.mobileApp.action]:
              finalStep(TicketStep),
            [CloudCategoryStep.webApp.action]:
              finalStep(TicketStep),
            [CloudCategoryStep.alexa.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.google.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.siri.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.defects.action]:
              finalStep(TicketStep),
            [CloudCategoryStep.returns.action]:
              finalStep(TicketStep),
            [CloudCategoryStep["433mhz"].action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep["868mhz"].action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.bluetooth.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.infrared.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.wifi.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.zigbee.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.zwave.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.family.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.homeyBridge.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [CloudCategoryStep.other.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
          }
        ),
      [MainCategoryStep.homey.pro.action]:
        actionStep(ProCategoryStep, {
            [ProCategoryStep.apps.action]:
              actionStep(AppsStep, {
                  [AppsStep.contactSupport.action]:
                    step(KnowledgeBaseStep,
                      finalStep(TicketStep)
                    ),
                  [AppsStep.contactDeveloper.action]:
                    step(ContactDeveloperStep,
                      finalStep(EmailDeveloperStep)
                    ),
                  [AppsStep.notListed.action]:
                    step(KnowledgeBaseStep,
                      finalStep(TicketStep)
                    ),
                }
              ),
            [ProCategoryStep.devices.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.flow.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.advancedFlow.action]:
              finalStep(TicketStep),
            [ProCategoryStep.logic.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.insights.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.energy.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.mobileApp.action]:
              finalStep(TicketStep),
            [ProCategoryStep.webApp.action]:
              finalStep(TicketStep),
            [ProCategoryStep.alexa.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.google.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.siri.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.defects.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.returns.action]:
              finalStep(TicketStep),
            [ProCategoryStep["433mhz"].action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep["868mhz"].action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.bluetooth.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.early2023.matter.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.infrared.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.early2023.thread.action]:
              step(KnowledgeBaseStep,
                  finalStep(TicketStep)
              ),
            [ProCategoryStep.wifi.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.zigbee.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.zwave.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.family.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.ethernetAdapter.action]:
              step(KnowledgeBaseStep,
                  finalStep(TicketStep)
              ),
            [ProCategoryStep.satellite.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.backups.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
            [ProCategoryStep.other.action]:
              step(KnowledgeBaseStep,
                finalStep(TicketStep)
              ),
          }
        ),
      [MainCategoryStep.homey.notListed.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
      [MainCategoryStep.orders.order.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
      [MainCategoryStep.orders.notListed.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
      [MainCategoryStep.subscriptions.backups.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
      [MainCategoryStep.subscriptions.premium.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
      [MainCategoryStep.subscriptions.developer.action]:
        finalStep(TicketStep),
      [MainCategoryStep.subscriptions.notListed.action]:
        step(KnowledgeBaseStep,
          finalStep(TicketStep)
        ),
    }
  );

// Use for linear steps, where the following step is predetermined.
function step(Component, next) {
  return {
    type: stepTypes.step,
    Component: Component,
    next: next
  }
}

// Use for branching steps, where the action of the user determines the following step.
function actionStep(Component, actions = {}) {
  return {
    type: stepTypes.actionStep,
    Component: Component,
    actions: actions
  }
}

// Use for steps which don't result in a following step.
function finalStep(Component) {
  return {
    type: stepTypes.finalStep,
    Component: Component,
  }
}
