import React from "react";

import {Picture} from "../components/Picture";

export function LoginPage() {
  const articleLink = "https://support.homey.app/hc/articles/7811639393692-I-m-having-difficulty-signing-in"

  const fullLineupImage = "/img/pages/support-form/login/full-lineup.png";

  return (
      <div className="support-form-login">
        <div className="edge">
          <div className="support-form-login__content">
            <div className="support-form-login__image-wrapper">
              <Picture
                  className="support-form-login__image"
                  src={fullLineupImage}
                  alt="Lineup of Homey products">
              </Picture>
            </div>
            <h1 className="support-form-login__title">How can we help?</h1>
            <p className="support-form-login__description">Please sign in so we can help you best.</p>
            <button type="button" data-login=""
                    className="gradient-button-blue-large-shadow support-form-login__button">
              Sign in
            </button>
            <a href={articleLink} target="_blank" rel="noopener noreferrer" className="support-form-login__link">
              Can't sign in?
            </a>
          </div>
        </div>
        <div className="support-form-login__background"/>
      </div>
  )

}
