import React, {useState, useEffect, useRef} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../../managers/DataManager";
import {flowManager} from "../../managers/FlowManager";
import {userManager} from "../../managers/UserManager";
import {dataKeys} from "../../enums/dataKeys";
import {useTicketApi} from "../../hooks/useTicketApi";
import {Modal} from "../../components/Modal";
import {Picture} from "../../components/Picture";
import {StepHeader} from "../../components/StepHeader";
import {SubjectInput} from "../../components/SubjectInput";
import {MessageInput} from "../../components/MessageInput";
import {FilesInput} from "./components/FilesInput";

TicketStep.propTypes = {
  index: PropTypes.number,
}

export function TicketStep(props) {
  const name = TicketStep.name;
  const stepRef = useRef(null);
  const diagnosticRef = useRef(null);
  const isMountedRef = useRef(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ticketApi, ticketIsSending, ticketError, setTicketError] = useTicketApi();

  const paperPlaneImage = "/img/pages/support-form/result-popup/paper-plane.png";

  // On mount, scroll step into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  // On mount, if a Homey is selected, generate a Diagnostic Report.
  // Generating a Diagnostic Report can take up to 30 seconds, during which the user can press send.
  // To allow for await before sending the ticket, the promise is added to dataManager.pendingPromises.
  useEffect(() => {
    const homey = userManager.getSelectedHomey();
    if (!homey) return;

    isMountedRef.current = true;

    function getDiagnosticReport() {
      const entry = { name }

      homey.authenticate()
        .then((homeyApi) => {
          const diagnosticReportPromise = homeyApi.system.sendLog({ $timeout: 30000 });
          dataManager.pendingPromises.diagnosticReportPromise = diagnosticReportPromise;
          return diagnosticReportPromise;
        })
        .then((diagnosticReport) => {
          entry[dataKeys.diagnosticReport] = diagnosticReport;
        })
        .catch((error) => {
          console.error("Couldn't generate diagnostic report:", error);
          entry[dataKeys.diagnosticReport] = "Couldn't generate report";
        })
        .finally(() => {
          // If the step is changed while getting the Diagnostic Report, don't update data.
          if (isMountedRef.current) {
            dataManager.updateData(props.index, entry);
          }
          delete dataManager.pendingPromises.diagnosticReportPromise;
        });
    }

    if (!diagnosticRef.current) {
      diagnosticRef.current = true;
      getDiagnosticReport();
    }

    return () => {
      isMountedRef.current = false;
    }
  }, [name, props.index])


  async function handleSubmit(e) {
    e.preventDefault(); // Prevent Form from instantly resetting on submit.
    setIsError(false);

    const isFormValid = flowManager.finalStep(props.index);
    if (!isFormValid || isOpen) return;

    try {
      const ticketCreated = await ticketApi.sendTicket();
      if (!ticketCreated) {
        return setIsError(true);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(`Could not send ticket! ${error}`);
      setTicketError("We’ve encountered a problem sending your message. Please try again.");
      setIsError(true);
    }
  }


  return (
    <form className="support-form-ticket-step" onSubmit={handleSubmit} noValidate>
      <div ref={stepRef} tabIndex={0} className="support-form-step">
        <StepHeader
          index={props.index}
          step={name}
          question="We're happy to help."
          description="Please provide as much detail as possible. This will help us to answer you faster and better."
        >
          <div className="support-form-ticket-step__information">
            {/*<div className="support-form-ticket-step__response-time">*/}
            {/*  <div className="support-form-ticket-step__response-time-icon"/>*/}
            {/*  <div>*/}
            {/*    <p className="support-form-ticket-step__response-time-title">Expected response time: 1-2 weeks</p>*/}
            {/*    <p className="color-text-light-body">We're sorry for the delayed support due to high demand.</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="support-form-ticket-step__community">
              <div className="support-form-ticket-step__community-icon"/>
              <div>
                <p className="support-form-ticket-step__community-title">Ask the Homey Community</p>
                <p className="color-text-light-body">Our awesome community might be able to answer your question on the <a href="https://community.homey.app" target="_blank" rel="noreferrer">Homey Community Forum</a>.</p>
              </div>
            </div>
          </div>
        </StepHeader>
        <div className="support-form-ticket-step__input-wrapper">
          <SubjectInput index={props.index} name={name}/>
          <MessageInput index={props.index} name={name}/>
          <FilesInput index={props.index} name={name}/>
        </div>
      </div>

      <div className="support-form-ticket-step__submit-wrapper">
        <div>
          <div className="support-form-ticket-step__corner-line"/>
          <div className="support-form-ticket-step__bottom-line"/>
          <div className="support-form-ticket-step__arrow-line"/>
        </div>
        <button type="submit" className={`gradient-button-medium-blue-shadow support-form-ticket-step__submit 
        ${ticketIsSending && "is-disabled"}`}>
          <span className={`support-form-ticket-step__submit-icon ${ticketIsSending && "is-loading"}`}/>
          <span>{ticketIsSending ? "Sending..." : "Send"}</span>
        </button>
      </div>
      {isError ? (
        <div className="support-form-ticket-step__error-wrapper">
          <p className="support-form-ticket-step__error">
            {ticketError}
          </p>
        </div>
      ) : (
        <div className="support-form-ticket-step__disclaimer-wrapper">
          <p className="support-form-ticket-step__disclaimer">
            Some diagnostics of Homey will be shared with the developer to
            help them troubleshoot your question faster and better.
          </p>
        </div>
      )}

      <Modal isOpen={isOpen}>
        <div className="support-form-results__popup">
          <div className="support-form-results__image-wrapper">
            <Picture
              src={paperPlaneImage}
              alt="Paper plane">
            </Picture>
          </div>
          <h2 className="support-form-results__header">
            Thanks for your message!
          </h2>
          <p className="support-form-results__text">
            Your e-mail confirmation is on its way. Our support team is working to get back to you as soon as
            possible.
          </p>
          <a href="https://support.homey.app" className="gradient-button-medium-green">
            Return to Knowledge Base
          </a>
        </div>
      </Modal>
    </form>
  );
}
