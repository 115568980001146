import {useEffect, useRef, useState} from "react";

// This hook returns an array of orders and boolean isLoading state.
export function useOrders() {
  const ref = useRef(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hyWebsite = window.hyWebsite;
    const storeApi = hyWebsite.getStoreAPI();

    function getOrders() {
      storeApi.getOrders()
        .then((orders) => {
          setOrders(orders)
        })
        .catch((error) => {
          console.error("Could not retrieve orders:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (!ref.current && storeApi.storeApiUserReady) {
      ref.current = true;
      getOrders();
    }

    storeApi.on("user", getOrders);

    return () => storeApi.off("user", getOrders);
  }, [])

  return [orders, isLoading]
}
