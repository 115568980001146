import {useEffect, useRef, useState} from "react";
import {AthomAppsAPI} from 'homey-api';

import {userManager} from "../managers/UserManager";

// Get app details for selected Homey. Returns apps and loading state of the request.
export function useApps() {
  const ref = useRef(null);
  const [apps, setApps] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const athomAppsApi = new AthomAppsAPI();
    const homey = userManager.getSelectedHomey();
    const apps = {};

    homey?.apps?.forEach((app) => {
      apps[app.id] = {
        channel: app.channel,
        details: null,
        id: app.id,
        version: app.version,
      }
    })

    function getApps(homey) {
      Promise.allSettled(
        homey?.apps?.map((app) => {
          return athomAppsApi.getApp({ appId: app.id })
        })
      ).then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            apps[result.value.id].details = result.value;
          }
          if (result.status === "rejected") {
            console.error("Could not get app details:", result.status, result.reason);
          }
        })
        setApps(apps);
        setIsLoading(false);
      })
    }

    if (!ref.current) {
      ref.current = true;
      getApps(homey);
    }
  }, [])

  return [apps, isLoading]
}
