import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../../managers/DataManager";
import {flowManager} from "../../managers/FlowManager";
import {userManager} from "../../managers/UserManager";
import {dataKeys} from "../../enums/dataKeys";
import {useOrders} from "../../hooks/useOrders";
import {StepHeader} from "../../components/StepHeader";
import {StepCategory} from "../../components/StepCategory";
import {TileCheckbox} from "../../components/TileCheckbox";
import {MainCategoryTile} from "./components/MainCategoryTile";
import {Picture} from "../../components/Picture";

MainCategoryStep.propTypes = {
  index: PropTypes.number
}

export function MainCategoryStep(props) {
  const name = MainCategoryStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const user = userManager.store(state => state.user); // User is updated on state change.
  const stepRef = useRef(null);
  const [checkValue, setCheckValue] = useState("");
  const [orders, isLoading] = useOrders();
  const options = {};

  const homeyImage = "/img/pages/support-form/main-category/homey.png";
  const homeyBridgeImage = "/img/pages/support-form/main-category/homey-bridge.png";
  const homeyPro2019Image = "/img/pages/support-form/main-category/homey-pro-2019.png";
  const homeyPro2023Image = "/img/pages/support-form/main-category/homey-pro-2023.png";
  const homeyUnlistedImage = "/img/pages/support-form/main-category/homey-unlisted.png";

  const orderImage = "/img/pages/support-form/main-category/order.png";
  const orderUnlistedImage = "/img/pages/support-form/main-category/order-unlisted.png";

  const backupsImage = "/img/pages/support-form/main-category/backups.png";
  const developerImage = "/img/pages/support-form/main-category/developer.png";
  const premiumImage = "/img/pages/support-form/main-category/premium.png";
  const subscriptionUnlistedImage = "/img/pages/support-form/main-category/subscription-unlisted.png";

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name])

  function handleSelect(e) {
    const value = e.target.value;
    const data = options[value].data;
    const action = data.action;
    const entry = { name, value, ...data };

    flowManager.nextActionStep(props.index, entry, action);
  }

  function getFormattedDate(date) {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  }

  function getAttributesByHomey(homey) {
    if (homey.platform === 'cloud') {
      return {
        subtitle: homey.bridges.length > 0 ? "With Homey Bridge" : "Without Homey Bridge",
        image: homey.bridges.length > 0 ? homeyBridgeImage : homeyImage,
        alt: homey.bridges.length > 0 ? "Homey Bridge" : "Homey",
        data: {
          ...MainCategoryStep.homey.cloud,
          [dataKeys.homeyApps]: homey.apps.length,
          [dataKeys.homeyBridges]: homey.bridges.length,
          [dataKeys.homeyId]: homey.id,
          [dataKeys.homeyLanguage]: homey.language,
          [dataKeys.homeyLicenses]: homey.licenses.join(", "),
          [dataKeys.homeyPlatform]: homey.platform,
          [dataKeys.homeyRegion]: homey.region,
          [dataKeys.homeyUsers]: homey.users.length
        }
      }
    }
    if (homey.platform === 'local') {
      return {
        subtitle: homey.modelName,
        image: getHomeyImage(homey.model),
        alt: "Homey Pro",
        data: {
          ...MainCategoryStep.homey.pro,
          [dataKeys.homeyApps]: homey.apps.length,
          [dataKeys.homeyBridges]: homey.bridges.length,
          [dataKeys.homeyId]: homey.id,
          [dataKeys.homeyLanguage]: homey.language,
          [dataKeys.homeyLicenses]: homey.licenses.join(", "),
          [dataKeys.homeyModel]: homey.model,
          [dataKeys.homeyModelName]: homey.modelName,
          [dataKeys.homeyPlatform]: homey.platform,
          [dataKeys.homeyRegion]: homey.region,
          [dataKeys.homeySoftwareVersion]: homey.softwareVersion,
          [dataKeys.homeyUsers]: homey.users.length
        }
      }
    }
  }

  function getHomeyImage(model) { // TODO: Check other models
    switch (model) {
      case "homey5q":
        return homeyPro2023Image;
      case "homey3d":
      default:
        return homeyPro2019Image;
    }
  }

  function getAttributesBySubscriptionId(subscriptionId) {
    if (subscriptionId.startsWith('homey_plus_')) {
      return {
        title: "Homey Pro Backups",
        image: backupsImage,
        alt: "Homey Pro Backups subscription card",
        data: { ...MainCategoryStep.subscriptions.backups, [dataKeys.subscriptionId]: subscriptionId }
      };
    }
    if (subscriptionId.startsWith('homey_premium_')) {
      return {
        title: "Homey Premium",
        image: premiumImage,
        alt: "Homey Premium subscription card",
        data: { ...MainCategoryStep.subscriptions.premium, [dataKeys.subscriptionId]: subscriptionId }
      };
    }
    if (subscriptionId.startsWith('homey_developer_')) {
      return {
        title: "Homey Developer",
        image: developerImage,
        alt: "Homey Developer subscription card",
        data: { ...MainCategoryStep.subscriptions.developer, [dataKeys.subscriptionId]: subscriptionId }
      };
    }
    return {
      title: subscriptionId,
      image: subscriptionUnlistedImage,
      alt: "Blank subscription card",
      data: { ...MainCategoryStep.subscriptions.notListed, [dataKeys.subscriptionId]: subscriptionId }
    };
  }

  function addImage(image, alt, type) {
    return <Picture className={`support-form-main-category-tile__image--${type}`} src={image} alt={alt}/>
  }


  // Account
  options[`account-0`] = {
    title: user.fullname,
    subtitle: user.email,
    image: <img className="support-form-main-category-tile__image--round" src={user.avatar.medium}
                srcSet={`${user.avatar.large} 2x`} alt={"Account avatar"}/>,
    data: { ...MainCategoryStep.account }
  };

  // Homeys
  let homeyIndex = 0;
  user?.homeys?.forEach((homey) => {
    const attributes = getAttributesByHomey(homey);
    options[`homey-${homeyIndex}`] = {
      title: homey.name,
      subtitle: attributes.subtitle,
      image: addImage(attributes.image, attributes.alt, "full"),
      data: { ...attributes.data }
    };
    homeyIndex++;
  });
  options[`homey-${homeyIndex}`] = {
    text: "My Homey\nisn't listed",
    image: addImage(homeyUnlistedImage, "Blank Homey image", "full"),
    data: { ...MainCategoryStep.homey.notListed }
  };

  // Orders
  let orderIndex = 0;
  orders?.forEach((order) => {
    options[`order-${orderIndex}`] = {
      title: `Order #${order.id}`,
      subtitle: getFormattedDate(order.date),
      image: addImage(orderImage, "Shopping bag image", "full"),
      data: { ...MainCategoryStep.orders.order, [dataKeys.orderId]: order.id }
    };
    orderIndex++;
  });
  options[`order-${orderIndex}`] = {
    text: "My order\nisn't listed",
    image: addImage(orderUnlistedImage, "Blank shopping bag image", "full"),
    data: { ...MainCategoryStep.orders.notListed }
  };

  // Subscriptions
  let subscriptionIndex = 0;
  Object.entries(user.subscriptions).forEach(([subscriptionId, subscription]) => {
    const attributes = getAttributesBySubscriptionId(subscriptionId);
    options[`subscription-${subscriptionIndex}`] = {
      title: attributes.title,
      subtitle: `Since ${getFormattedDate(subscription.startedAt)}`,
      image: addImage(attributes.image, attributes.alt, "full"),
      data: { ...attributes.data }
    };
    subscriptionIndex++;
  })
  options[`subscription-${subscriptionIndex}`] = {
    text: "My subscription\nisn't listed",
    image: addImage(subscriptionUnlistedImage, "Blank subscription image", "full"),
    data: { ...MainCategoryStep.subscriptions.notListed }
  };

  return (
    <>
      <div ref={stepRef} className="support-form-step">
        <StepHeader
          index={props.index}
          question="What's your question about?"
          description="We’d love to help you out! First we need to know what your question is about."
          isLoading={isLoading}
        />
        <div className="support-form-step__categories">
          <StepCategory heading="My Account" description="Your name, password, 2FA and more.">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("account") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <MainCategoryTile title={option.title} subtitle={option.subtitle} image={option.image}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory
            heading="My Homeys"
            description="Get help with Devices, Flows, Apps, Insights, Voice Assistants and more.">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("homey") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <MainCategoryTile title={option.title} subtitle={option.subtitle} text={option.text}
                                    image={option.image}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="My Orders" description="Your order's status, invoices and returns.">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("order") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <MainCategoryTile title={option.title} subtitle={option.subtitle} text={option.text}
                                    image={option.image}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="My Subscriptions" description="Help with billing, renewal and cancellation.">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("subscription") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <MainCategoryTile title={option.title} subtitle={option.subtitle} text={option.text}
                                    image={option.image}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
        </div>
      </div>
      {steps.length === props.index + 1 && (
        <div className="support-form-step">
          <StepHeader
            index={props.index + 1}
            question="Select one of the above options."
          />
        </div>
      )}
    </>
  );
}


MainCategoryStep.account = {
  action: "account",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["account"]
}
MainCategoryStep.homey = {
  cloud: {
    action: "cloud",
    [dataKeys.articles]: [],
    [dataKeys.tags]: ["homey", "cloud"]
  },
  pro: {
    action: "pro",
    [dataKeys.articles]: [],
    [dataKeys.tags]: ["homey", "pro"]
  },
  notListed: {
    action: "homeyNotListed",
    [dataKeys.articles]: [4409632440978, 7262272408732, 360009667354],
    [dataKeys.tags]: ["homey", "not-listed"]
  }
}
MainCategoryStep.orders = {
  order: {
    action: "order",
    [dataKeys.articles]: [360024756873],
    [dataKeys.tags]: ["order"]
  },
  notListed: {
    action: "orderNotListed",
    [dataKeys.articles]: [360024756873],
    [dataKeys.tags]: ["order", "not-listed"]
  }
}
MainCategoryStep.subscriptions = {
  backups: {
    action: "subscriptionBackups",
    [dataKeys.articles]: [360010668340, 4410239526290, 360011315299, 9302284997660],
    [dataKeys.tags]: ["subscription", "backups"]
  },
  developer: {
    action: "subscriptionDeveloper",
    [dataKeys.articles]: [],
    [dataKeys.tags]: ["subscription", "developer"]
  },
  premium: {
    action: "subscriptionPremium",
    [dataKeys.articles]: [4411823204754],
    [dataKeys.tags]: ["subscription", "premium"]
  },
  notListed: {
    action: "subscriptionNotListed",
    [dataKeys.articles]: [4411915109138],
    [dataKeys.tags]: ["subscription", "not-listed"]
  }
}
