import {useEffect, useState} from "react";

import {flowManager} from "../managers/FlowManager";
import {userManager} from "../managers/UserManager";

// This hook returns an isLoading state for the CloudAPI and sets the user.
export function useCloudApi() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function onLoad(){
      // window.hyWebsite may not be accessible yet on window load.
      // This happens for example in Firefox when the user navigates back to the Support Form after navigating away.
      if (!window.hyWebsite) {
        window.addEventListener("hyWebsiteReady", getCloudApiUser);
        return;
      }

      getCloudApiUser();
    }

    // Initialize the cloudApi and get the user.
    function getCloudApiUser() {
      const hyWebsite = window.hyWebsite;
      const cloudApi = hyWebsite.getCloudAPI();
      const cloudApiUser = cloudApi.getUser();
      if (cloudApiUser) {
        setCloudApiUser(cloudApiUser);
      }
      cloudApi.on('user', setCloudApiUser);
      setIsLoading(false);
    }

    // Set the user state to the current user and reset the form if no user.
    function setCloudApiUser(cloudApiUser) {
      if (!cloudApiUser) {
        flowManager.reset();
      }
      userManager.setUser(cloudApiUser);
    }

    window.addEventListener("load", onLoad);

    return () => window.removeEventListener("load", onLoad);
  }, [])

  return [isLoading]
}
