import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import PropTypes from 'prop-types';

import {useMediaQuery} from "../hooks/useMediaQuery";

StepCategory.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  heading: PropTypes.string
}

export function StepCategory(props) {
  const isSwiperMediaQuery = useMediaQuery("(hover: none), (max-width: 991px)");

  return (
    <div className="support-form-step-category">
      <h3 className="support-form-step-category__heading">{props.heading}</h3>
      {props.description && (
        <p className="support-form-step-category__description">{props.description}</p>
      )}
      {/* For touch devices, render Swiper. Otherwise, render a static grid. */}
      {isSwiperMediaQuery ? (
        <Swiper
          className="support-form-step-category__swiper"
          spaceBetween={12}
          slidesPerView={"auto"}
        >
          {props.children.map((item, index) => {
            return item && <SwiperSlide key={index}>{item}</SwiperSlide>
          })}
        </Swiper>
      ) : (
        <div className="support-form-step-category__items">{props.children}</div>
      )}
    </div>
  );
}
