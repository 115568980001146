import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {flowManager} from "../managers/FlowManager";
import {dataKeys} from "../enums/dataKeys";
import {useArticles} from "../hooks/useArticles";
import {StepHeader} from "../components/StepHeader";
import {ArticleList} from "../components/ArticleList";

ContactDeveloperStep.propTypes = {
  index: PropTypes.number
}

export function ContactDeveloperStep(props) {
  const name = ContactDeveloperStep.name;
  const stepRef = useRef(null);
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const [articles, isLoading] = useArticles();
  const supportType = dataManager.getValueByKey(dataKeys.supportType);
  const author = dataManager.getValueByKey(dataKeys.appAuthor);
  const build = dataManager.getValueByKey(dataKeys.appBuild);
  const appName = typeof build.name === 'string' ? build.name : build.name.en;
  const support = {};

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  function handleClick() {
    const action = "next";
    const entry = { name, action };
    flowManager.nextStep(props.index, entry);
  }

  switch (supportType) {
    case "supportWebsite":
      support.description = "The developer has provided the website below for any questions or requests.";
      support.link = {
        text: "Visit the Developer's Support Website",
        href: build.support
      };
      break;
    case "email":
      support.description = "The developer has requested to be notified by e-mail for any questions or requests.";
      support.button = {
        text: "Send an E-mail",
      };
      break;
    case "bugsWebsite":
      support.description = "The developer has provided the website below as a point of contact for any bugs.";
      support.link = {
        text: "Report an Issue",
        href: build.bugs
      };
      break;
    case "communityForum":
      support.description = "You can contact the developer via the Homey Community Forum.";
      support.link = {
        text: "Visit the Homey Community Forum",
        href: `https://community.homey.app/t/${build.homeyCommunityTopicId}`
      };
      break;
    default:
      support.description = "Due to the developer's request to not be notified, you are unable to contact them for questions or requests.";
      support.link = {
        text: "Visit the Homey Community Forum",
        href: "https://community.homey.app/"
      };
  }

  if (steps.length > props.index + 1) return null;

  return (
    <div ref={stepRef} tabIndex={0} className="support-form-step support-form-contact-developer-step">
      <StepHeader
        index={props.index}
        question="This might help you."
        description="These articles may answer your question."
        isLoading={isLoading}
      />
      <ArticleList articles={articles} isLoading={isLoading} />
      <h3 className="support-form-contact-developer-step__subtitle">Still not found what you were looking for?</h3>
      <p className="support-form-contact-developer-step__description">
        {author.verified
          ? `${appName} was created by a verified developer.`
          : `${appName} was created by a community developer and is not an official app.`
        }
      </p>
      <p className="support-form-contact-developer-step__description">
        {support.description}
      </p>
      {support.button && (
        <button type="button" className="support-form-contact-developer-step__button" onClick={handleClick}>
          <span>{support.button.text}</span>
          <span className="mask-arrow-right"/>
        </button>
      )}
      {support.link && (
        <>
          <a href={support.link.href}
             target="_blank"
             rel="noopener noreferrer"
             className="support-form-contact-developer-step__link"
          >
            <span>{support.link.text}</span>
            <span className="mask-arrow-right"/>
          </a>
          <div className="support-form-contact-developer-step__line-end"></div>
        </>
      )}
    </div>
  )
}
