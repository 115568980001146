import create from "zustand";

import {dataManager} from "./DataManager";
import {dataKeys} from "../enums/dataKeys";

class UserManager {
  constructor() {
    this.store = create(() => ({
      user: null,
    }))
  }

  // Managers have a store and setter, getter, action and util functions.
  // Setter and getter functions only set and get states from the corresponding store.
  // Action functions contain logic when to set and get and interact with other Managers.
  // Util functions help to keep code clean and prevent errors.

  /***** Setters *****/
  // Set the user.
  setUser(user) {
    this.store.setState(() => {
      return {
        user: user
      }
    });

    // console.info('setUser', user);
  }

  /***** Getters *****/
  // Return the current state of the user.
  getUser() {
    return this.store.getState().user;
  }

  // Return the currently selected Homey, or null.
  getSelectedHomey() {
    const user = this.store.getState().user;
    const homeyId = dataManager.getValueByKey(dataKeys.homeyId);

    if (!homeyId) {
      return null;
    }
    return user.getHomeyById(homeyId);
  }
}

export const userManager = new UserManager();
