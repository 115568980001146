import React from "react";
import PropTypes from 'prop-types';

TileCheckbox.propTypes = {
  check: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export function TileCheckbox(props) {

  return (
    <label className="support-form-tile-checkbox">
      <input
        className="support-form-tile-checkbox__input"
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.check === props.value}
        onChange={props.onChange}
      />
      <div className="support-form-tile-checkbox__tile">
        {props.children}
        <div className="support-form-tile-checkbox__icon-circle">
          <div className="support-form-tile-checkbox__icon"/>
        </div>
      </div>
    </label>
  );
}
