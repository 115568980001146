import {useState} from "react";

import {dataManager} from "../managers/DataManager";
import {userManager} from "../managers/UserManager";
import {dataKeys} from "../enums/dataKeys";

export function useAppDiagnosticReport() {
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function send() {
    try {
      setErrorMessage("")
      setIsSending(true);
      const homey = userManager.getSelectedHomey();
      const appId = dataManager.getValueByKey(dataKeys.appId);
      const message = dataManager.getValueByKey(dataKeys.message).text;

      const homeyApi = await homey.authenticate();
      await homeyApi.apps.getAppStd({ id: appId, message });

      return true;
    } catch (error) {
      switch (error.statusCode) {
        case 500:
          setErrorMessage(`Unfortunately, we were unable to generate a diagnostic report of the app to send with the email because your Homey appears to be offline.`);
          break;
        default:
          setErrorMessage(`We’ve encountered a problem sending the email. ${error?.message ?? error}.`);
          break;
      }
      console.error(`Could not send email! ${error}`);

      return false;
    } finally {
      setIsSending(false);
    }
  }

  return [{ send }, isSending, errorMessage, setErrorMessage]
}
