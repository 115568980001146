import React from "react";
import PropTypes from "prop-types";

import {dataManager} from "../managers/DataManager";
import {dataKeys} from "../enums/dataKeys";
import {ArticleLink} from "./ArticleLink";

ArticleList.propTypes = {
  articles: PropTypes.array,
  isLoading: PropTypes.bool
}

export function ArticleList(props) {
  const articleIds = dataManager.getFlatArrayOfUniqueValuesByKey(dataKeys.articles);

  if (props.isLoading) {
    return (
      <div className="support-form-article-list">
        {articleIds.map((index) => (
          <ArticleLink key={index} isLoading/>
        ))}
      </div>
    )
  }

  return (
    <div className="support-form-article-list">
      {props.articles.map((article, index) => (
        <ArticleLink key={index} url={article.html_url} name={article.name}/>
      ))}
    </div>
  );
}
