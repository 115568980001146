import create from "zustand";

class DataManager {
  constructor() {
    this.store = create(() => ({
      data: []
    }))
    this.pendingPromises = {}
  }

  /***** Setters *****/
  // Set data to an array of entries.
  setData(entries = []) {
    // console.info('setData', { 'entries': entries });
    this.store.setState(() => {
      return {
        data: [...entries]
      }
    });
  }

  // Add an entry object to the end of the data array.
  setNewData(entry = {}) {
    // console.info('setNewData', { 'entry': entry });
    this.store.setState((state) => {
      return {
        data: [...state.data, entry]
      }
    });
  }

  /***** Getters *****/
  // Return the current state of the data array.
  getData() {
    // console.info('getData', this.store.getState().data);
    return this.store.getState().data;
  }

  // Return the value corresponding to the first match to the key.
  getValueByKey(key) {
    // console.info('getValueByKey', {'key': key});
    const data = this.getData();
    const entry = data.find((item) => {
      return Object.keys(item).some((searchKey) => searchKey === key);
    })
    // console.info('getValueByKey', {'entry': entry});
    return entry ? entry[key] : ""
  }

  // Return an array of values corresponding to all matches to the key.
  getValuesByKey(key) {
    // console.info('getValuesByKey', {'key': key});
    const results = [];
    const data = this.getData();
    const entries = data.filter((item) => {
      return Object.keys(item).some((searchKey) => searchKey === key);
    })
    entries.forEach((entry, index) => {
      results[index] = entry[key];
    });
    return results;
  }

  // Return a flat array of unique values corresponding to all matches to the key.
  getFlatArrayOfUniqueValuesByKey(key) {
    // console.info('getFlatArrayOfUniqueValuesByKey', {'key': key});
    const arrayOfValues = this.getValuesByKey(key); // Get potentially nested array
    const flatArrayOfValues = arrayOfValues.flat(); // Flatten array
    return [...new Set(flatArrayOfValues)]; // Remove duplicates
  }

  /***** Actions *****/
  // Removes all data entries after the given index ( > index ).
  clearEntriesFromIndex(index) {
    // console.info('clearEntriesFromIndex', { 'index': index });
    const data = this.getData();
    const newData = data.slice(0, index);
    this.setData(newData);
  }

  // Removes all entries and returns an empty array.
  clearAllEntries() {
    // console.info('clearAllEntries');
    this.store.setState(() => {
      return {
        data: []
      }
    });
  }

  // Update data entry based on index.
  updateData(index, entry = {}) {
    // console.info('updateData', { 'entry': entry });
    const data = this.getData();
    if (data.length === index) {
      this.setNewData(entry);
    }
    if (data.length > index) {
      const newData = [...data];
      newData[index] = { ...newData[index], ...entry };
      this.setData(newData);
    }
    if (data.length < index) {
      console.error("Missing data entry");
    }
  }
}

export const dataManager = new DataManager();

