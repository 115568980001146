import React, {useState, useId, useEffect} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {dataKeys} from "../enums/dataKeys";

SubjectInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string
}

export function SubjectInput(props) {
  const name = props.name;
  const subjectId = useId();
  const [subject, setSubject] = useState({ text: "", required: true, valid: false });
  const showValidation = dataManager.getValueByKey(dataKeys.showValidation);

  useEffect(() => {
    const entry = { name, [dataKeys.subject]: subject };
    dataManager.updateData(props.index, entry);
  },[subject, name, props.index])

  function handleTextInput(e) {
    const minLength = 1;
    const maxLength = 200;

    setSubject({
      ...subject,
      text: e.target.value.length <= maxLength ? e.target.value : e.target.value.slice(0, maxLength),
      valid: e.target.value.length >= minLength,
    });
  }

  return (
    <div className="support-form-subject-input__wrapper">
      <label htmlFor={subjectId} className="support-form-subject-input__label">
        Subject <span className="support-form-subject-input__label-note">(required)</span>
      </label>
      <input
        type="text"
        id={subjectId}
        name="subject"
        className={`support-form-subject-input__input ${(showValidation && !subject.valid) && "is-invalid"}`}
        value={subject.text}
        placeholder="What's your question about?"
        onInput={handleTextInput}
      />
      <span className={`support-form-subject-input__validation ${(showValidation && !subject.valid) && "is-invalid"}`}>
        Please add a subject.
      </span>
    </div>
  );
}
