import create from "zustand";

import {stepTypes} from "../config/flowConfig";
import {dataManager} from "./DataManager";
import {dataKeys} from "../enums/dataKeys";

class FlowManager {
  constructor() {
    this.store = create(() => ({
      steps: [],
    }))
  }

  // Managers have a store and setter, getter, action and util functions.
  // Setter and getter functions only set and get states from the corresponding store.
  // Action functions contain logic when to set and get and interact with other Managers.
  // Util functions help to keep code clean and prevent errors.

  /***** Setters *****/
  // Set steps by using a config array. E.g. the initial config.
  setSteps(config = []) {
    this.store.setState(() => {
      return {
        steps: [...config]
      }
    });
  }

  // Set steps by given the config array for the next step.
  setNewStep(nextConfig) {
    this.store.setState((state) => {
      return {
        steps: [...state.steps, nextConfig]
      }
    });
  }

  // Set the form to step by index.
  setToStep(index) {
    this.store.setState((state) => {
      return {
        steps: state.steps.slice(0, index + 1)
      }
    });
  }

  /***** Getters *****/
  // Get the steps array.
  getSteps() {
    return this.store.getState().steps;
  }

  // Get the step based on the given index of the steps array.
  getStepByIndex(index) {
    const steps = this.store.getState().steps;
    return steps[index];
  }


  /***** Actions *****/
  // Add the next step to the form and the corresponding entry to data based on the given index.
  nextStep(index, entry = {}) {
    const steps = this.getSteps();
    const stepConfig = steps[index];
    if (!this.isValidStepType(stepTypes.step, stepConfig)) return;

    // If the current step is not the last step, reset to current step.
    if (steps.length > index + 1) {
      this.setToStep(index);
      dataManager.clearEntriesFromIndex(index);
    }

    // Set next step.
    const nextConfig = stepConfig.next;
    this.setNewStep(nextConfig);
    dataManager.setNewData(entry);

    // console.info('nextStep', { 'action:': 'next' }, nextConfig.Component.name, nextConfig);
  }

  // Add the next step to the form and the corresponding entry to data based on the given action and index.
  nextActionStep(index, entry = {}, action) {
    const steps = this.getSteps();
    // console.info('steps', steps);
    const stepConfig = steps[index];
    // console.info('stepConfig', stepConfig);
    if (!this.isValidStepType(stepTypes.actionStep, stepConfig)) return;

    // If the current step is not the last step, reset to current step.
    if (steps.length > index + 1) {
      // console.info('Reset Steps');
      this.setToStep(index);
      dataManager.clearEntriesFromIndex(index);
    }

    // Set next step.
    const nextConfig = stepConfig.actions[action];
    this.setNewStep(nextConfig);
    dataManager.setNewData(entry);

    // console.info('nextActionStep', { 'action:': action }, nextConfig.Component.name, nextConfig);
  }

  // Validate the form and if not valid, show validation to the user.
  finalStep(index) {
    const stepConfig = this.getStepByIndex(index);
    // console.info('Final Step:', stepConfig);
    if (!this.isValidStepType(stepTypes.finalStep, stepConfig)) return false;

    const subject = dataManager.getValueByKey(dataKeys.subject);
    const message = dataManager.getValueByKey(dataKeys.message);

    if (subject.required && !subject.valid) {
      const entry = { [dataKeys.showValidation]: true };
      dataManager.updateData(index, entry);

      // console.info("Form is invalid!")
      return false;
    }

    if (message.required && !message.valid) {
      const entry = { [dataKeys.showValidation]: true };
      dataManager.updateData(index, entry);

      // console.info("Form is invalid!")
      return false;
    }
    // console.info("Form is valid!")
    return true;
  }

  // Reset the form.
  reset() {
    // console.info("Reset");
    this.setToStep(0)
    dataManager.clearAllEntries();
  }


  /***** UTILS *****/
  isValidStepType(type, config) {
    if (type !== config.type) {
      console.error('wrong configuration', config);
      return false;
    }

    return true;
  }
}

export const flowManager = new FlowManager();
