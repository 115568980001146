import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {flowManager} from "../managers/FlowManager";
import {dataManager} from "../managers/DataManager";
import {StepHeader} from "../components/StepHeader";
import {StepCategory} from "../components/StepCategory";
import {TileCheckbox} from "../components/TileCheckbox";
import {SubCategoryTile} from "../components/SubCategoryTile";
import {dataKeys} from "../enums/dataKeys";

AccountCategoryStep.propTypes = {
  index: PropTypes.number
}

export function AccountCategoryStep(props) {
  const name = AccountCategoryStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const stepRef = useRef(null);
  const [checkValue, setCheckValue] = useState("");
  const options = {};

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name])

  function handleSelect(e) {
    const value = e.target.value;
    const data = options[value].data;
    const action = data.action;
    const entry = { name, value, ...data };

    flowManager.nextActionStep(props.index, entry, action);
  }

  // General
  options[`general-0`] = {
    title: "Name / E-mail",
    icon: "name",
    data: { ...AccountCategoryStep.nameAndMail }
  };
  options[`general-1`] = {
    title: "Profile Picture",
    icon: "profile-picture",
    data: { ...AccountCategoryStep.profile }
  };
  options[`general-2`] = {
    title: "Delete Account",
    icon: "delete",
    data: { ...AccountCategoryStep.delete }
  };
  options[`general-3`] = {
    title: "Other",
    icon: "other",
    data: { ...AccountCategoryStep.other }
  };

  // Security
  options[`security-0`] = {
    title: "Password",
    icon: "password",
    data: { ...AccountCategoryStep.password }
  };
  options[`security-1`] = {
    title: "2FA",
    icon: "mfa",
    data: { ...AccountCategoryStep.mfa }
  };

  return (
    <>
      <div ref={stepRef} tabIndex={0} className="support-form-step">
        <StepHeader
          index={props.index}
          question="How can we help you with your account?"
          description="Choose the category that describes your question or problem best."
        />
        <div className="support-form-step__categories">
          <StepCategory heading="General">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("general") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
          <StepCategory heading="Security">
            {Object.entries(options).map(([value, option]) => {
              return value.startsWith("security") && (
                <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                  <SubCategoryTile title={option.title} icon={option.icon}/>
                </TileCheckbox>
              )
            })}
          </StepCategory>
        </div>
      </div>
      {steps.length === props.index + 1 && (
        <div className="support-form-step">
          <StepHeader
            index={props.index + 1}
            question="Select one of the above options."
          />
        </div>
      )}
    </>
  );
}


AccountCategoryStep.nameAndMail = {
  action: "nameAndMail",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["name", "e-mail"]
}
AccountCategoryStep.profile = {
  action: "profile",
  [dataKeys.articles]: [360019083514],
  [dataKeys.tags]: ["profile-picture"]
}
AccountCategoryStep.delete = {
  action: "delete",
  [dataKeys.articles]: [4413923314962],
  [dataKeys.tags]: ["delete"]
}
AccountCategoryStep.other = {
  action: "other",
  [dataKeys.articles]: [360012615814],
  [dataKeys.tags]: ["other"]
}
AccountCategoryStep.password = {
  action: "password",
  [dataKeys.articles]: [360007737779, 7811639393692],
  [dataKeys.tags]: ["password"]
}
AccountCategoryStep.mfa = {
  action: "mfa",
  [dataKeys.articles]: [360020967199, 7811639393692],
  [dataKeys.tags]: ["2fa"]
}


