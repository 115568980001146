import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import smoothscroll from 'smoothscroll-polyfill';

import {flowConfig} from "./config/flowConfig";
import {flowManager} from "./managers/FlowManager";
import {userManager} from "./managers/UserManager";
import {useCloudApi} from "./hooks/useCloudApi";
import {LoadingPage} from "./pages/LoadingPage";
import {LoginPage} from "./pages/LoginPage";
import {FormPage} from "./pages/FormPage";
import fetch from "node-fetch";

// Initialize Support Form config
flowManager.setSteps([flowConfig]);

function App() {
  const user = userManager.store(state => state.user); // User is updated on state change.
  const [isLoading] = useCloudApi();

  // Polyfill for smooth scrolling (e.g. scrollIntoView)
  useEffect(() => {
    smoothscroll.polyfill();
  }, [])

  // Reset form when user navigates back to the page.
  useEffect(() => {
    const resetForm = (e) => {
      if (e.persisted) {
        flowManager.reset();
      }
    }
    window.addEventListener("pageshow", resetForm);
    return () => window.removeEventListener("pageshow", resetForm);
  }, []);

  // Fallback for very old browsers: https://caniuse.com/?search=fetch
  if (typeof fetch !== "function") {
    alert("Sorry, your browser is too old and no longer supported. Please contact support by sending an email to info@athom.nl.");
    return null;
  }

  if (isLoading) return <LoadingPage />
  if (!user) return <LoginPage />
  return <FormPage />
}

const domContainer = document.querySelector('#support-form-root');
const root = ReactDOM.createRoot(domContainer);
// root.render(<App/>);
root.render(<React.StrictMode><App/></React.StrictMode>);
