import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import PropTypes from 'prop-types';

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool
}

export function Modal(props) {
  const modalRef = useRef(null);

  // Prevent scroll and trap focus inside the modal.
  useEffect(() => {
    if (!props.isOpen) return;

    modalRef.current.focus({ preventScroll: true }); // Set focus to modal on render
    document.body.style.overflow = "hidden" // Prevent scroll behind modal

  // Trap focus inside the modal by catching Tab and Shift+Tab events.
    const trapFocus = (e) => {
      if (e.key === 'Tab') {
        const focusableElements = modalRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          return lastElement.focus();
        }
        if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          return firstElement.focus();
        }
      }
    };
    document.addEventListener('keydown', trapFocus);

    return () => {
      document.body.style.overflow = "initial"
      document.removeEventListener('keydown', trapFocus);
    };
    // }
  }, [props.isOpen]);

  // Create Portal outside app to render modal.
  function Portal({ children }) {
    const [modalContainer] = useState(document.createElement("div"));

    useEffect(() => {
      const portalRoot = document.getElementById("support-form-portal-root");
      portalRoot.appendChild(modalContainer);

      return function cleanup() {
        portalRoot.removeChild(modalContainer);
      };
    }, [modalContainer]);
    return createPortal(children, modalContainer);
  }

  if (!props.isOpen) return null;

  return (
    <Portal>
      <div className="support-form-modal">
        <div ref={modalRef} tabIndex={0} className="support-form-modal__content">
          {props.children}
        </div>
      </div>
    </Portal>
  );
}
