import React from "react";

export function LoadingPage() {

  return (
    <div className="support-form-loading">
      <div className="support-form-loading__loading-throbber"></div>
      <span className="support-form-loading__text">Help is on the way...</span>
    </div>
  );
}
