import {useEffect, useState} from "react";

// This hook returns a boolean matches state based on the given query.
// Source: https://fireship.io/snippets/use-media-query-hook/
// So, if the query matches the current window.matchMedia, it returns true.
// example:
// const mediaQuery = useMediaQuery("(max-width: 991px)");
// if (mediaQuery) { ... }
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};
