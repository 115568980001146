import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {flowManager} from "../managers/FlowManager";
import {dataKeys} from "../enums/dataKeys";
import {useAppDiagnosticReport} from "../hooks/useAppDiagnosticReport";
import {Picture} from "../components/Picture";
import {StepHeader} from "../components/StepHeader";
import {MessageInput} from "../components/MessageInput";
import {Modal} from "../components/Modal";

EmailDeveloperStep.propTypes = {
  index: PropTypes.number
}

export function EmailDeveloperStep(props) {
  const name = EmailDeveloperStep.name;
  const stepRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [appDiagnosticReport, isSending, errorMessage, setErrorMessage] = useAppDiagnosticReport();
  const build = dataManager.getValueByKey(dataKeys.appBuild);

  const paperPlaneImage = "/img/pages/support-form/result-popup/paper-plane.png";

  // On mount, scroll step into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  async function handleSubmit(e) {
    e.preventDefault(); // Prevent Form from instantly resetting on submit.
    setIsError(false);

    const isFormValid = flowManager.finalStep(props.index);
    if (!isFormValid || isOpen) return;

    try {
      const appDiagnosticReportSent = await appDiagnosticReport.send();
      if (!appDiagnosticReportSent) {
        return setIsError(true);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(`Could not send email! ${error}`);
      setErrorMessage(`We’ve encountered a problem sending your message: ${error}.`);
      setIsError(true);
    }
  }

  return (
    <form className="support-form-email-developer-step" onSubmit={handleSubmit} noValidate>
      <div ref={stepRef} tabIndex={0} className="support-form-step">
        <StepHeader
          index={props.index}
          step={name}
          question="Send an e-mail to the developer."
          description="Please provide as much detail as possible. This will help the developer to answer you faster and better."
        >
        </StepHeader>
        <div className="support-form-email-developer-step__input-wrapper">
          <MessageInput index={props.index} name={name}/>
        </div>
      </div>

      <div className="support-form-email-developer-step__submit-wrapper">
        <div>
          <div className="support-form-email-developer-step__corner-line"/>
          <div className="support-form-email-developer-step__bottom-line"/>
          <div className="support-form-email-developer-step__arrow-line"/>
        </div>
        <button type="submit" className={`gradient-button-medium-blue-shadow support-form-email-developer-step__submit 
        ${isSending && "is-disabled"}`}>
          <span className={`support-form-email-developer-step__submit-icon ${isSending && "is-loading"}`}/>
          <span>{isSending ? "Sending..." : "Send"}</span>
        </button>
      </div>
      {isError ? (
        <div className="support-form-email-developer-step__error-wrapper">
          <p className="support-form-email-developer-step__error">
            {errorMessage} Please try again or feel free to send an email to the developer <a href={build.support}>here</a>.
          </p>
        </div>
      ) : (
        <div className="support-form-email-developer-step__disclaimer-wrapper">
          <p className="support-form-email-developer-step__disclaimer">
            Some diagnostics of Homey will be shared with the developer to
            help them troubleshoot your question faster and better.
          </p>
        </div>
      )}

      <Modal isOpen={isOpen}>
        <div className="support-form-results__popup">
          <div className="support-form-results__image-wrapper">
            <Picture
              src={paperPlaneImage}
              alt="Paper plane">
            </Picture>
          </div>
          <h2 className="support-form-results__header">
            Thanks for your message!
          </h2>
          <p className="support-form-results__text">
            Your e-mail has been sent to the developer.
          </p>
          <a href="https://support.homey.app" className="gradient-button-medium-green">
            Return to Knowledge Base
          </a>
        </div>
      </Modal>
    </form>
  )
}
