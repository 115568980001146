import React from "react";
import PropTypes from 'prop-types';

MainCategoryTile.propTypes = {
  image: PropTypes.node,
  text: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string
}

export function MainCategoryTile(props) {

  return (
    <div className="support-form-main-category-tile">
      <div className="support-form-main-category-tile__image-wrapper">
        {props.image}
      </div>
      <div className="support-form-main-category-tile__text-wrapper">
        {props.title && (
          <p className="support-form-main-category-tile__title">{props.title}</p>
        )}
        {props.subtitle && (
          <p className="support-form-main-category-tile__subtitle">{props.subtitle}</p>
        )}
        {props.text && (
          <p className="support-form-main-category-tile__text">{props.text}</p>
        )}
      </div>
    </div>
  );
}
