import {useEffect, useRef, useState} from "react";

import {dataManager} from "../managers/DataManager";
import {dataKeys} from "../enums/dataKeys";

// This hook returns an array of articles and the loading state of the request.
export function useArticles() {
  const ref = useRef(null);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getArticleById(id) {
    try {
      const getArticleByIdResponse = await fetch(`/api/zendesk/article?id=${id}`, {
        method: 'GET',
      });
      if (!getArticleByIdResponse.ok) {
        throw new Error(getArticleByIdResponse.statusText);
      }
      return await getArticleByIdResponse.json();
    } catch (error) {
      return Promise.reject(`Could not find article with id=${id}. ${error}`)
    }
  }

  useEffect(() => {
    const articleIds = dataManager.getFlatArrayOfUniqueValuesByKey(dataKeys.articles);

    function getArticles(articleIds) {
      Promise.allSettled(
        articleIds.map((id) => {
          return getArticleById(id)
        })
      ).then((results) => {
        const articles = [];

        results.forEach((result) => {
          if (result.status === "fulfilled") {
            articles.push(result.value);
          }
          if (result.status === "rejected") {
            console.error(result.reason);
          }
        });

        // console.info(`Found the following articles: ${articles}`);
        setArticles(articles);
        setIsLoading(false);
      })
    }

    if (!ref.current) {
      ref.current = true;
      getArticles(articleIds);
    }
  }, [])

  return [articles, isLoading]
}
