import React from "react";

import PropTypes from 'prop-types';

SubCategoryTile.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string
}

export function SubCategoryTile(props) {

  return (
    <div className="support-form-sub-category-tile">
      <div className="support-form-sub-category-tile__icon-wrapper">
        <div className={`support-form-sub-category-tile__icon-circle support-form-sub-category-tile__icon-circle--${props.icon}`}>
          <div className={`support-form-sub-category-tile__icon support-form-sub-category-tile__icon--${props.icon}`}/>
        </div>
      </div>
      <div className="support-form-sub-category-tile__text-wrapper">
        <span className="support-form-sub-category-tile__title">{props.title}</span>
      </div>
    </div>
  );
}
