import React from "react";
import PropTypes from 'prop-types';

ArticleLink.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string
}

export function ArticleLink(props) {

  if (props.isLoading) {
    return (
      <div className="support-form-article-link is-loading">
        <div className="support-form-article-link__text is-loading"/>
        <div className="support-form-article-link__icon mask-square-arrow-up-right"/>
      </div>
    )
  }

  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer" className="support-form-article-link">
      <div className="support-form-article-link__text">{props.name}</div>
      <div className="support-form-article-link__icon mask-square-arrow-up-right"/>
    </a>
  );
}
