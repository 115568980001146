import React, {useEffect, useId, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../../../managers/DataManager";
import {dataKeys} from "../../../enums/dataKeys";

FilesInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string
}

export function FilesInput(props) {
  const name = props.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const filesRef = useRef(null);
  const filesId = useId();
  const [files, setFiles] = useState({});
  const [filesValidation, setFilesValidation] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);

  // Update local state of files
  useEffect(() => {
    setFiles(dataManager.getValueByKey(dataKeys.files));
  }, [data])

  // Adding and removing files from the FileList isn't possible by default, as the FileList is read-only.
  // To allow for adding/removing files, the FileList is copied, and it's state is managed manually.
  // Filesize cannot exceed 50MB per file.
  function addFiles(newFilesValues) {
    setFilesValidation("");
    const oldFilesValues = Object.values(files);
    const newFileList = { ...files };
    newFilesValues.forEach((newFile) => {
      if (Object.keys(newFileList).length >= 12) {
        setFilesValidation("Maximum number of attachments reached. Cannot add more than 12 files.");
        // console.info(`${newFile.name} could not be added. This would exceed maximum number of attachments: 12.`);
        return
      }
      if (newFile.size > 50000000) {
        setFilesValidation("File exceeds the maximum file size of 50MB and could not be added.");
        // console.info(`${newFile.name} exceeds the maximum file size of 50MB and could not be added.`);
        return
      }
      if (!oldFilesValues.some(oldFile => oldFile.name === newFile.name)) {
        newFileList[Object.keys(newFileList).length] = newFile;
      }
    })
    // console.info("New file list:", newFileList);
    const entry = { name, [dataKeys.files]: newFileList };
    dataManager.updateData(props.index, entry);
  }

  // This function links the styled "Add file"-button to the hidden files input element.
  function handleClick() {
    filesRef.current.click();
  }

  // Get the selected files from the files input element and pass the values to the addFiles() function.
  function handleSelectFiles(e) {
    const newFilesValues = Object.values(e.target.files);
    addFiles(newFilesValues);
  }

  // Prevent default actions on drag (i.e. opening the file).
  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  // Toggle boolean value of isDragOver state.
  function toggleIsDragOver() {
    setIsDragOver(prevIsDragOver => !prevIsDragOver);
  }

  // Use the DataTransfer interface to access the dropped files and pass the values to the addFiles() function.
  function handleDropFiles(e) {
    e.preventDefault();
    e.stopPropagation();
    const newFilesValues = Object.values(e.dataTransfer.files);
    addFiles(newFilesValues);
    setIsDragOver(false);
  }

  // Delete the file on the given key of the enumerated files object and renumber the other keys.
  function handleRemoveFiles(e) {
    setFilesValidation("");
    const newFileList = {};
    let count = 0;
    Object.keys(files).forEach((key) => {
      if (key !== e.target.value) {
        newFileList[count++] = files[key];
      }
    })
    // console.info("New file list:", newFileList);
    const entry = { name, [dataKeys.files]: newFileList };
    dataManager.updateData(props.index, entry);
  }

  return (
    <div className="support-form-files-input__wrapper trim">
      <label htmlFor="files" className="support-form-files-input__label">
        Attachments <span className="support-form-files-input__label-note">(optional)</span>
      </label>
      {Object.values(files).map((file, index) => {
        return (
          <div key={index} className="support-form-files-input__file">
            <span>{file.name}</span>
            <button
              type="button"
              value={index}
              className="support-form-files-input__remove-file"
              onClick={handleRemoveFiles}
            />
          </div>
        )
      })}
      <button
        type="button"
        tabIndex={0}
        className={`support-form-files-input__add-files ${isDragOver && "is-dragover"}`}
        onDrop={handleDropFiles}
        onDragOver={handleDragOver}
        onDragEnter={toggleIsDragOver}
        onDragLeave={toggleIsDragOver}
        onClick={handleClick}>
        <span>Add file</span>
      </button>
      <input
        ref={filesRef}
        id={filesId}
        name="files"
        className="support-form-files-input__add-files-input"
        onChange={handleSelectFiles}
        type="file"
        multiple
      />
      <p className={`support-form-files-input__description ${filesValidation && "is-invalid"}`}>
        {filesValidation ??
          "An image can be worth a thousand words. Providing screenshots or screencasts will help a lot. Max file size 50MB."
        }
      </p>
    </div>
  )
}
