import React from "react";
import PropTypes from 'prop-types';

Picture.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  loading: PropTypes.oneOf(["eager", "lazy"]),
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  webp: PropTypes.bool,
  width: PropTypes.number
}

Picture.defaultProps = {
  loading: "lazy",
  webp: true
}

export function Picture(props) {
  const width = props.width;
  const height = props.height;
  let sources = props.src;

  if (typeof src !== "object") {
    sources = { 0: props.src };
  }

  const sortedSources = sortSources(sources);
  const extendedSources = extendSources(sortedSources);

  // Sort sources based on size, from small to large
  function sortSources(sources) {
    const sortedKeys = Object.keys(sources).sort((a, b) => a - b);
    const sortedSources = {};

    sortedKeys.forEach((key) => {
      sortedSources[key] = sources[key];
    });

    return sortedSources;
  }

  // Extend source information with media, type and srcSet for retina displays
  function extendSources(sources) {
    let extendedSourcesArray = [];

    Object.entries(sources).forEach(([key, value]) => {
      let size = key;
      let src = value;

      if (src.endsWith('.png') || src.endsWith('.jpg')) {
        const type = src.endsWith('.png') ? 'image/png' : 'image/jpeg';
        const src2x = src.replace('.png', '@2x.png').replace('.jpg', '@2x.jpg');
        const media = (size !== 'portrait' && size !== 'landscape') ? `min-width: ${size}px` : `orientation: ${size}`;

        if (props.webp) {
          const webpSrc = src.replace('.png', '.webp').replace('.jpg', '.webp');
          const webpSrc2x = src.replace('.png', '@2x.webp').replace('.jpg', '@2x.webp');
          extendedSourcesArray.push({
            srcSet: `${webpSrc} 1x, ${webpSrc2x} 2x`,
            type: "image/webp",
            media: `(${media})`
          });
        }
        extendedSourcesArray.push({
          srcSet: `${src} 1x, ${src2x} 2x`,
          type: type,
          media: `(${media})`
        });
      }
    })

    return extendedSourcesArray;
  }

  return (
    <picture>
      {extendedSources.map(({ media, srcSet, type }, index) => (
        <source key={index} media={media} srcSet={srcSet} type={type}/>
      ))}
      <img className={props.className}
           src={extendedSources[0].src}
           loading={props.loading}
           alt={props.alt}
           {...{ width, height }}
      />
    </picture>
  )
}
