import React from "react";

import {dataManager} from "../managers/DataManager";
import {flowManager} from "../managers/FlowManager";
import {userManager} from "../managers/UserManager";

export function FormPage() {
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const user = userManager.store(state => state.user); // User is updated on state change.

  return (
    <div className="support-form">
      <header className="support-form__header">
        <div className="support-form__header-icon support-form__header-icon--left-bubble"/>
        <div className="support-form__header-icon support-form__header-icon--right-bubble"/>
        <div className="edge trim">
          <h1 className="support-form__header-title">Hi {user.firstname}, <br className="sm-no-br"/> How can we
            help?</h1>
          <h2 className="text-preset-hero-4 text-align-center">We're here to help you find answers to your
            questions.</h2>
        </div>
      </header>

      <div className="support-form__steps">
        {steps.map((flowConfig, index) => {
          return (
            // Give steps a unique key based on the value chosen in the previous step. Because selecting a
            // different option in the same step should remount the next step regardless of which action is
            // selected.
            <flowConfig.Component key={data[index - 1]?.value ?? index} index={index}/>
          );
        })}
      </div>
    </div>
  );
}
