import React, {useState, useId, useEffect} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {dataKeys} from "../enums/dataKeys";

MessageInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string
}

export function MessageInput(props) {
  const name = props.name;
  const messageId = useId();
  const [message, setMessage] = useState({ text: "", required: true, valid: false });
  const showValidation = dataManager.getValueByKey(dataKeys.showValidation);

  useEffect(() => {
    const entry = { name, [dataKeys.message]: message };
    dataManager.updateData(props.index, entry);
  }, [message, name, props.index])

  function handleTextInput(e) {
    const minLength = 20;
    const maxLength = 10000;

    setMessage({
      ...message,
      text: e.target.value.length <= maxLength ? e.target.value : e.target.value.slice(0, maxLength),
      valid: e.target.value.length >= minLength,
    });
  }

  return (
    <div className="support-form-message-input">
      <label htmlFor={messageId} className="support-form-message-input__label">
        What's your question? <span className="support-form-message-input__label-note">(required)</span>
      </label>
      <div className={`support-form-message-input__input-container 
      ${(showValidation && !message.valid) && "is-invalid"}`}>
        <textarea
          id={messageId}
          name="message"
          className="support-form-message-input__input"
          value={message.text}
          placeholder="Please include as much details as you can."
          onInput={handleTextInput}
        />
      </div>
      <span
        className={`support-form-message-input__validation ${(showValidation && !message.valid) && "is-invalid"}`}>
        A minimum of 20 characters is required to describe your issue.
      </span>
    </div>
  );
}
